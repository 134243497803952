@import url('./fonts/stylesheet.css');

html,
body {
  margin: 0;
  font-family: 'RiftSoft', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'RiftSoft', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.page {
  margin-left: 80px;
}
@media screen and (max-width: 768px) {
  .page {
    margin-left: 0;
    margin-bottom: 100px;
  }
}
.page.login {
  margin-left: 0;
}
.wrapper {
  max-width: 80%;
  margin: 0 auto;
}

.wrapper h1 {
  font-size: 2.2em !important;
  font-weight: bold !important;
}

.wrapper .tableWrapper > div {
  box-shadow: none;
}

.editOverlay {
  padding: 0 !important;
  background: transparent !important;
}
.editModal {
  position: fixed !important;

  right: 0;
  height: 100%;
  width: 50%;
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: 0 2px 9px 0 rgba(120, 47, 10, 0.1) !important;
  border: 0;
}

textarea.questions:focus {
  box-shadow: none !important;
}
.rdt_Table,
.rdt_Pagination,
.rdt_TableHeadRow,
.rdt_TableRow {
  background-color: transparent !important;
}

/* The animation code */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes background {
  0% {
    background-position: 0% 1000000px;
  }

  100% {
    background-position: 0 0;
  }
}
a:focus,
a:active {
  background: none;
}
a:-webkit-any-link:focus {
  outline-offset: none;
}

.dashboard.printing * {
  border-radius: 0 !important;
}
