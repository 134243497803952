.login {
  background-color: #1a202c;
  /* background-color: rgb(26, 32, 44); */
}
.login-container {
  justify-content: space-evenly;
  width: 80%;
}

.loginwrap {
  border-radius: 0 !important;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
}
.loginBackground {
  position: absolute;
  height: 100vh;
  left: 0;
}

.loginBackground:after {
  content: ' ';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  transform-origin: bottom left;
  transform: skew(5deg, 0deg);
}

.loginBackground:before {
  content: ' ';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #e30014;
  transform-origin: bottom left;
  transform: skew(-2deg, 0deg);
}
@media only screen and (max-width: 768px) {
  .loginBackground:after {
    bottom: 0;
    top: auto;
    height: 55vh;
    transform: none;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  }
  .loginBackground:before {
    bottom: 0;
    top: auto;
    height: 55vh;
    transform: none;
    clip-path: polygon(0 0, 100% 8%, 100% 100%, 0% 100%);
  }
  .page {
    margin-bottom: 0;
  }
}

.login-container h1 {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.4em;
}
/* @media only screen and (max-width: 1200px) {
  .login-container h1 {
    background-color: lightblue;
  }
} */
.registration-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.registration-form > input {
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-form > input {
  margin-bottom: 10px;
}
