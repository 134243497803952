@font-face {
  font-family: 'RiftSoft';
  src: url('RiftSoft-Medium.eot');
  src: url('RiftSoft-Medium.eot?#iefix') format('embedded-opentype'),
    url('RiftSoft-Medium.woff2') format('woff2'),
    url('RiftSoft-Medium.woff') format('woff'),
    url('RiftSoft-Medium.ttf') format('truetype'),
    url('RiftSoft-Medium.svg#RiftSoft-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RiftSoft';
  src: url('RiftSoft-BoldItalic.eot');
  src: url('RiftSoft-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('RiftSoft-BoldItalic.woff2') format('woff2'),
    url('RiftSoft-BoldItalic.woff') format('woff'),
    url('RiftSoft-BoldItalic.ttf') format('truetype'),
    url('RiftSoft-BoldItalic.svg#RiftSoft-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
